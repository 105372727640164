var generic = generic || {};

(function ($) {
  'use strict';

  // variables
  var loyaltyCancellationpopupV1 = {
    signed_in: !!parseInt(site.userInfoCookie.getValue('signed_in')),
    loyalty_level: parseInt(site.userInfoCookie.getValue('loyalty_level')),
    is_loyalty_member: parseInt(site.userInfoCookie.getValue('is_loyalty_member')),
    revalidate: 0,
    show_overlay: 0
  };

  Drupal.behaviors.loyaltyCancellationpopupV1 = {
    get: function (key) {
      return loyaltyCancellationpopupV1[key];
    },
    set: function (key, val) {
      loyaltyCancellationpopupV1[key] = val;
    },
    attach: function (context, settings) {
      var self = this;
      var $template_faq = $('.js-loyalty-cancellation-faq', context);

      $template_faq
        .find('.loyalty_cancellation')
        .once()
        .on('click', function (e) {
          var $template = $('.js-loyalty-cancellation-popup--v1', context);
          var signedIn = self.get('signed_in');

          // initialize cta buttons
          self._initButtons($template);
          var $wrapper = $template.find('.cancellation-loyalty-sign-in-component');

          if (!signedIn) {
            var $signin = $wrapper.find('.js-cancellation-signin-form-container');

            $signin.removeClass('hidden');
          } else {
            var $confirm = $wrapper.find('.js-cancellation-confirmation-form-container');

            $confirm.removeClass('hidden');
          }
          // show popup
          if (!self.get('revalidate')) {
            self.cancellationColorbox($template);
          }
        });
    },

    /**
     * Show cancellation colorbox
     */
    cancellationConfirmColorbox: function ($template) {
      var self = this;
      var $wrapper = $template.find('.cancellation-loyalty-sign-in-component');
      var $confirm = $wrapper.find('.js-cancellation-confirmation-form-container');
      // containers whose default state is hidden
      var hiddenContainers = [
        'js-cancellation-signin-form-container',
        'js-cancellation-confirmation-form-container',
        'js-cancellation-confirmation-success-form-container'
      ];
      // reset all containers back to their default state
      // this is to handle cases where we are revalidating after user state change
      var $overlayConfirmForm = $wrapper;

      $.each(hiddenContainers, function (index, value) {
        $overlayConfirmForm.find('.' + value).addClass('hidden');
      });

      // container that show form errors
      var errorContainers = [
        'form--errors--loyalty-cancellation-signin',
        'form--errors--loyalty-cancellation-confirmation'
      ];

      // reset errors
      // this is to handle cases where we are revalidating after user state change
      $.each(errorContainers, function (index, value) {
        $('#' + value).html();
      });

      $confirm.removeClass('hidden');
      // show popup
      if (!self.get('revalidate')) {
        self.cancellationColorbox($template);
        $.colorbox.resize();
      }
    },
    /**
     * Show confirmation success colorbox
     */
    cancellationConfirmSuccessColorbox: function ($template) {
      var self = this;
      var $wrapper = $template.find('.cancellation-loyalty-sign-in-component');
      var $success = $wrapper.find('.js-cancellation-confirmation-success-form-container');
      // containers whose default state is hidden
      var hiddenContainers = [
        'js-cancellation-signin-form-container',
        'js-cancellation-confirmation-form-container',
        'js-cancellation-confirmation-success-form-container'
      ];
      // reset all containers back to their default state
      // this is to handle cases where we are revalidating after user state change
      var $overlayConfirmForm = $wrapper;

      $.each(hiddenContainers, function (index, value) {
        $overlayConfirmForm.find('.' + value).addClass('hidden');
      });

      // container that show form errors
      var errorContainers = [
        'form--errors--loyalty-cancellation-signin',
        'form--errors--loyalty-cancellation-confirmation'
      ];

      // reset errors
      // this is to handle cases where we are revalidating after user state change
      $.each(errorContainers, function (index, value) {
        $('#' + value).html();
      });

      $success.removeClass('hidden');
      // show popup
      if (!self.get('revalidate')) {
        self.cancellationColorbox($template);
        $.colorbox.resize();
      }
    },
    /**
     * Show colorbox with signin content
     */
    cancellationColorbox: function ($template) {
      var self = this;
      var popup_height = 'auto';
      var popup_width = '40%';

      if (site.client.isMobile == 1) {
        popup_width = '20%';
      }
      generic.overlay.launch({
        href: $template,
        inline: true,
        width: popup_width,
        fixed: false,
        escKey: false,
        closeButton: true,
        overlayClose: false,
        scrolling: false,
        cssClass: 'loyalty-cancellation-overlay',
        onComplete: function () {
          $.colorbox.resize();
        }
      });
    },
    // init sign in and join form buttons
    _initButtons: function ($template) {
      var self = this;
      var $wrapper = $template.find('.cancellation-loyalty-sign-in-component');
      // bind signin form
      var returnURL = returnURL || window.location.pathname + window.location.search;

      $('input[name=RETURN_URL]', $template.val(returnURL));
      $wrapper
        .find('.js-cancellation-loyalty-signin__button')
        .once()
        .on('click', function (e) {
          e.preventDefault();
          var params = {
            _SUBMIT: 'signin',
            EMAIL_ADDRESS: $wrapper.find('#cancellation-loyalty-sign-in-component__EMAIL_ADDRESS').val(),
            PASSWORD: $wrapper.find('#cancellation-loyalty-sign-in-component__PASSWORD').val()
          };

          // try and sign the user in
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              // set the new signed_in values
              site.userInfoCookie.init();
              self.set('signed_in', parseInt(site.userInfoCookie.getValue('signed_in')));
              self.set('is_loyalty_member', parseInt(site.userInfoCookie.getValue('is_loyalty_member')));
              self.set('loyalty_level', parseInt(site.userInfoCookie.getValue('loyalty_level')));

              // revalidate user info
              self.set('revalidate', 1);
              self.cancellationConfirmColorbox($template);
              $.colorbox.resize();
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errListNode = $template.find('#form--errors--loyalty-cancellation-signin');

              generic.showErrors(errorObjectsArray, errListNode, '');
              $.colorbox.resize();
            }
          });
        });
      // bind loyalty cancellation form
      $wrapper
        .find('.js-cancellation-loyalty-confirm__button')
        .once()
        .on('click', function (e) {
          e.preventDefault();

          if (!document.getElementById('loyalty_cancellation_popup_cancel_confirm').checked) {
            $('#form--errors--loyalty-cancellation-confirmation').removeClass('hidden');

            return false;
          }
          var params = {
            _SUBMIT: 'loyalty_join',
            LEAVE_CONFIRM: 1,
            LEAVE_CONFIRM_PRESENT: 1
          };

          // try and sign the user in
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              // revalidate user info
              self.set('revalidate', 1);
              self.cancellationConfirmSuccessColorbox($template);
              $.colorbox.resize();
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errListNode = $template.find('#form--errors--loyalty-cancellation-confirmation');

              generic.showErrors(errorObjectsArray, errListNode, '');
              $.colorbox.resize();
            }
          });
        });
    }
  };
})(jQuery);
